/**
 * Site search behavior.
 *
 * @package  Embark
 * @since    1.0.0
 */

/**
 * Import dependencies.
 */
import $ from 'jquery';
import * as helpers from '../global/helpers';
import {els, breakpoints} from './variables';

/**
 * Search trigger.
 *
 * @type {Node}
 */
const searchTriggers = document.querySelector( '.search-trigger' );

/**
 * Search form.
 *
 * @type {Node}
 */
const siteSearch = document.getElementById( 'js-site-search' );

/**
 * Search input for focusing on click.
 *
 * @type {Node}
 */
const searchInput = document.getElementById( 'js-search-input' );

/**
 * Class added to .site-search when open.
 *
 * @type {String}
 */
const siteSearchActiveClass = 'is-active';

/**
 * Class added to <html> when menu is open.
 *
 * @type {String}
 */
const htmlOverlayClass = 'html--overlay';

/**
 * Search Form.
 *
 * @type {String}
 */
const searchForm = document.querySelectorAll( '.search-form' );

/**
 * Toggles the site search container.
 */
function toggleSiteSearch() {
	if ( siteSearch.classList.contains( siteSearchActiveClass ) ) {
		closeSiteSearch();
	} else {
		openSiteSearch();
	}
}

/**
 * Opens the site search container.
 */
function openSiteSearch() {
	els.html.classList.add( htmlOverlayClass );
	searchTriggers.classList.add( siteSearchActiveClass );
	siteSearch.classList.add( siteSearchActiveClass );
	window.setTimeout( function() {
		searchInput.focus();
	}, 100 );
}

/**
 * Closes the site search container.
 */
function closeSiteSearch() {
	if ( ! els.html.classList.contains( 'html--menu-open' ) ) {
		els.html.classList.remove( htmlOverlayClass );
	}
	searchTriggers.classList.remove( siteSearchActiveClass );
	siteSearch.classList.remove( siteSearchActiveClass );
	searchInput.blur();
}

/**
 * Determines whether to open/close the site search container.
 *
 * @param  {Event} e
 */
function handleSearchState(e) {

	// Returns immediately if there is no search trigger on this document.
	if ( ! searchTriggers ) {
		return;
	}

	/**
	 * If the search is open, and the event target is a descendent of search, do nothing.
	 */
	if ( siteSearch.classList.contains( siteSearchActiveClass ) && ( e.target === siteSearch || $( e.target ).closest( siteSearch ).length > 0 ) ) {
		return;
	}

	/**
	 * If the search isn't open and the event target is the trigger or any of its children, then toggle search.
	 */
	if ( e.target === searchTriggers || $( e.target ).closest( '.search-trigger' ).length > 0 ) {
		toggleSiteSearch();
	} 
	/**
	 * Otherwise, close the menu.
	 */
	else {
		closeSiteSearch();
	}
}

/**
 * Handle search submit. Prevent empty form submissions.
 *
 * @param  {Event} e
 */
function handleSearchSubmit(e) {

	if ( e.target.querySelector( '.search-input' ).value == '' ) {
		e.preventDefault();
	}

}

document.addEventListener( 'click', handleSearchState );
if ( searchForm ) {
	$( searchForm ).on( 'submit', handleSearchSubmit );
}
