/**
 * This file uses Colorbox to open image and video links in a lightbox.
 */

/**
 * jQuery
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

/**
 * jQuery Colorbox
 *
 * A customizable lightbox plugin for jQuery.
 *
 * @see https://www.npmjs.com/package/jquery-colorbox
 * @see http://www.jacklmoore.com/colorbox/
 *
 * @since 1.0.0
 */
import colorbox from 'jquery-colorbox';

/**
 * A collection of helper functions for use throughout the project.
 *
 * @since 1.0.0
 */
import * as helpers from '../global/helpers';

/**
 * Common variables used throughout the project.
 *
 * @since 1.0.0
 */
import { breakpoints } from './variables';

/**
 * Add a classname to the set of elements, given that their direct
 * child is an img or picture element.
 *
 * @since 1.0.0
 *
 * @param {jQuery} $els      jQuery object of elements to loop through.
 * @param {String} className Class to add to elements if they have an img or picture as a direct child.
 */
export function addImageLinkClass( $els, className ) {
	$els.each( function() {
		if ( $( this ).find( 'img, picture' ).length) {
			$( this ).addClass( className );
		}
	});
}

/**
 * Launches links to valid image formats into a lightbox (via ColorBox) instead of opening within window
 *
 * @see http://www.jacklmoore.com/colorbox/
 *
 * @since  1.0.0
 */
function initImageLightBox() {
	/**
	 * Query selector for image links.
	 */
	const imageLinks = '[href*=".jpg"],[href*=".gif"],[href*=".png"],[href*=".svg"]';

	/**
	 * Add enlarge class to link if it contains an image.
	 */
	addImageLinkClass( $( imageLinks ), 'enlarge' );

	/**
	 * Open the image in a lightbox when the link is clicked.
	 */
	$( document ).on( 'click', imageLinks, function(e) {
		$.colorbox( {
			href : $( this ).attr( 'href' ),
			close : '',
			height : 'auto',
			width : 'auto',
			maxHeight : '90%',
			maxWidth : '90%',
			opacity : 0.7
		} );

		e.preventDefault();
	});
};

/**
 * Display YouTube, Vimeo or Wistia videos into a lightbox (via ColorBox)
 * if the viewport is above a certain size and the device is not iOS.
 *
 * @see http://www.jacklmoore.com/colorbox/
 *
 * @since 1.0.0
 * @since 1.0.1 Using regex matches to check what video service is being used and to pull out the video's ID to add into colorbox href.
 *
 */
function initVideoLightbox() {
	/**
	 * Query selector for video links.
	 */
	const videoLinks = '[href*="youtube.com"],[href*="youtu.be"],[href*="vimeo.com"],[href*="wistia.com"],[href*=".mp4"]';

	/**
	 * Regex patterns for matching various video URLs from popular services.
	 */
	const youtubeRegex = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\/?\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/;
	const vimeoRegex   = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;
	const wistiaRegex  = /(?:https?:\/\/(.+)?(wistia.com|wi.st)\/(medias|embed)\/)(.*)/;

	/**
	 * Add play-btn class to link if it contains an image.
	 */
	addImageLinkClass( $( videoLinks ), 'play-btn' );

	/**
	 * Open the video in a lightbox when the link is clicked.
	 */
	$( document ).on( 'click', videoLinks, function( e ) {
		const $link = $( e.target ).closest( videoLinks );
		const href  = $link.attr( 'href' );
		let cbHref  = false;

		/**
		 * Prevent links from opening normally. If the link ends up not being a valid video link,
		 * like a YouTube profile or channel, then those links will be forced to open in a new tab.
		 */
		e.preventDefault();

		// MP4
		if ( href.indexOf( '.mp4' ) >= 0 ) {
			cbHref = href;
		}

		// YouTube
		if ( ! cbHref ) {
			const youTubeMatches = href.match( youtubeRegex );
			if ( youTubeMatches !== null && href.match( /youtube.com\/user|youtube.com\/channel/ ) === null ) {
				cbHref = `https://www.youtube.com/embed/${ youTubeMatches[1] }?autoplay=1&hd=1&rel=0`;
			}
		}

		// Vimeo
		if ( ! cbHref ) {
			const vimeoMatches = href.match( vimeoRegex );
			if ( vimeoMatches !== null ) {
				cbHref = `https://player.vimeo.com/video/${ vimeoMatches[4] }?autoplay=1`;
			}
		}

		// Wistia
		if ( ! cbHref ) {
			const wistiaMatches = href.match( wistiaRegex );
			if ( wistiaMatches !== null ) {
				cbHref = `https://fast.wistia.net/embed/iframe/${ wistiaMatches[4] }?autoplay=1`;
			}
		}

		/**
		 * Only open colorbox if we have a value for cbHref and we aren't
		 * on a mobile device, like a phone or tablet.
		 */
		if ( cbHref && ! helpers.isTouchDevice() ) {
			/**
			 * Set lightbox width based on viewport size.
			 */
			let cbWidth = 853;
			if ( breakpoints.lg.aboveMin() ) {
				cbWidth = 980;
			} else if ( breakpoints.xs.belowMax() ) {
				cbWidth = 500;
			} else if ( breakpoints.sm.belowMax() ) {
				cbWidth = 650;
			}

			$.colorbox( {
				href: cbHref,
				close: "",
				iframe: 1,
				innerHeight: cbWidth * (9/16),
				innerWidth: cbWidth,
				initialHeight: 200,
				initialWidth: 200,
				opacity: 0.7
			} );
			console.log( 'opening colorbox' );
		} else {
			console.log( 'opening href' );
			/**
			 * Otherwise, open the link in a new tab/window.
			 */
			window.open( href );
		}
	} );
};

/**
 * Add title attributes to Colorbox buttons to pass compliance tests.
 *
 * @since 1.0.1
 */
function makeLightboxesAccessible() {
	$( '#cboxPrevious' ).attr( 'title', 'Previous' ).html( '<span class="screen-reader-text">Previous</span>' );
	$( '#cboxNext' ).attr( 'title', 'Next' ).html( '<span class="screen-reader-text">Next</span>' );
	$( '#cboxSlideshow' ).attr( 'title', 'Slideshow' ).html( '<span class="screen-reader-text">Slideshow</span>' );
	$( document ).bind( 'cbox_complete', function() {
		$( '.cboxIframe' ).attr( 'title', 'Modal Content' );
	} );
}

/**
 * Toggle overflow on html when colorbox is opened and closed.
 *
 * @since 1.0.1
 */
$( document ).bind( 'cbox_complete', function(e) {
	$( 'html' ).css( 'overflow', 'hidden' );
} );

$( document ).bind( 'cbox_closed', function(e) {
	$( 'html' ).css( 'overflow', 'auto' );
} );

document.addEventListener( 'DOMContentLoaded', () => {
	initImageLightBox();
	initVideoLightbox();
	makeLightboxesAccessible();
} );
