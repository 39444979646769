/**
 * Polyfills
 *
 * @package Embark
 * @since   1.0.1
 */

/**
 * Promise Polyfill
 *
 * @since 1.0.1
 */
import es6promise from 'es6-promise';
es6promise.polyfill();

/**
 * Imports svgxuse.
 *
 * A simple polyfill that fetches external SVGs referenced in <use>
 * elements when the browser itself fails to do so.
 *
 * @see https://www.npmjs.com/package/svgxuse
 *
 * @since 1.0.0
 */
import 'svgxuse';

/**
 * Cross-browser requestAnimationFrame function.
 *
 * @since 1.0.1
 */
window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function( f ){setTimeout( f, 1000 / 60 )};

/**
 * Polyfill to add support for creating custom events in IE9–11.
 *
 * @link https://stackoverflow.com/questions/26596123/internet-explorer-9-10-11-event-constructor-doesnt-work
 */
(function() {
    if (typeof window.CustomEvent === "function")
        return false; //If not IE

    function CustomEvent(event, params) {
        params = params || {
            bubbles: false,
            cancelable: false,
            detail: undefined
        };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
})();
