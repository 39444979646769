/**
 * Defines common theme variables needed across multiple js partials.
 *
 * @package  embark
 * @since    1.0.0
 */

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

/**
 * Breakpoint variables.
 *
 * You can pass your own array of breakpoint objects to match up with the
 * ones you have setup in your SASS files.
 *
 * @see ../global/breakpoints.js
 *
 * @since 1.0.0
 *
 * @type {Breakpoints}
 */
import { Breakpoints } from '../global/breakpoints';
export const breakpoints = new Breakpoints();

/**
 * Common elements referenced throughout the project.
 *
 * @since 1.0.0
 */
export const els = {
	html: document.documentElement,
	body: document.body,
	header: document.getElementById( 'js-site-header' ),
	navContainer: document.querySelector( '.nav-drawer' ),
	mainMenu: document.querySelector( '.primary-menu' ),
	navicon: document.getElementById( 'js-navicon' )
};

export const offsetAmount = () => {
	let offsetAmount = 0;

	// Add header offset if visible
	if ( ! $('body').hasClass('scrolled') ) {
		offsetAmount += els.header.offsetHeight;
	}

	// Add masthead offset if visible
	const $mastheadCompact = $('.masthead-compact');
	if ( $mastheadCompact.length && $mastheadCompact.hasClass('masthead-compact--inview') ) {
		offsetAmount += $mastheadCompact[0].offsetHeight;
	}

	// Add sticky section nav offset if visible
	const $stickySectionNav = $('.section-nav--sticky');
	// if ( $stickySectionNav.length ) {
	// 	const stickySectionNavOffset = $stickySectionNav[0].getBoundingClientRect().top;
	// 	if ( stickySectionNavOffset < 60 ) {
	// 		offsetAmount += stickySectionNavOffset;
	// 	}
	// }

	return offsetAmount;
}
