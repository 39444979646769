/**
 * Google Maps embeds.
 *
 * @package  JWCI
 * @since    1.0.0
 */

/**
 * Google Maps embeds.
 *
 * @since    1.0.0
 *
 * @link https://www.advancedcustomfields.com/resources/google-map/#google-map%20api
 */
( function( $ ) {

	/*
	 * Global variable for tracking the last known open marker window
	 */

	var previousInfoWindow = false;

	/*
	 *  new_map
	 *
	 *  This function will render a Google Map onto the selected jQuery element
	 *
	 *  @type	function
	 *  @date	8/11/2013
	 *  @since	4.3.0
	 *
	 *  @param	$el ( jQuery element )
	 *  @return	n/a
	 */

	function new_map( $el ) {

		// Marker.
		var $markers = $el.find( '.marker' );

		// Args.
		var args = {
			scrollwheel: false,
			zoom: 16,
			center: new google.maps.LatLng( 0, 0 ),
			fullscreenControl: false,
			mapTypeControlOptions: { mapTypeIds: [] },
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			streetViewControl: false,
		};

		// Create map.
		var map = new google.maps.Map( $el[0], args );

		// Add a markers reference.
		map.markers = [];

		// Add markers.
		$markers.each( function(){
			add_marker( $( this ), map );
		} );

		// Center map.
		center_map( map );

		// Return.
		return map;

	}

	/*
	 *  add_marker
	 *
	 *  This function will add a marker to the selected Google Map
	 *
	 *  @type	function
	 *  @date	8/11/2013
	 *  @since	4.3.0
	 *
	 *  @param	$marker ( jQuery element )
	 *  @param	map ( Google Map object )
	 *  @return	n/a
	 */
	function add_marker( $marker, map ) {

		// Latitude and Longitude.
		var latlng = new google.maps.LatLng( $marker.attr( 'data-lat' ), $marker.attr( 'data-lng' ) );

		// Icons.
		var markerIcon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';

		if ( $marker.attr( 'data-pin' ) == 'blue' ) {
			markerIcon = 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
		}

		// Create marker.
		var marker = new google.maps.Marker( {
			position	: latlng,
			map			: map,
			icon		: markerIcon
		} );

		// Add to array.
		map.markers.push( marker );

		// If marker contains HTML, add it to an infoWindow.
		if ( $marker.html() ) {
			// Create info window.
			var infowindow = new google.maps.InfoWindow( {
				content		: $marker.html()
			} );

			// Show info window when marker is clicked.
			google.maps.event.addListener( marker, 'click', function() {
				// If there is a last known open marker window, close it.
				if ( previousInfoWindow ) {
					previousInfoWindow.close();
				}

				// Open the clicked marker window.
				infowindow.open( map, marker );

				// Update the last known open marker window.
				previousInfoWindow = infowindow;
			} );
		}

	}

	/*
	*  center_map
	*
	*  This function will center the map, showing all markers attached to this map
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	map ( Google Map object )
	*  @return	n/a
	*/
	function center_map( map ) {

		// Bounds.
		var bounds = new google.maps.LatLngBounds();

		// Loop through all markers and create bounds.
		$.each( map.markers, function( i, marker ){

			var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

			bounds.extend( latlng );

		} );

		// only 1 marker?
		if ( map.markers.length == 1 ) {
			// Set center of map.
			map.setCenter( bounds.getCenter() );
			map.setZoom( 12 );
		} else {
			// Fit to bounds.
			map.fitBounds( bounds );
		}

	}

	/*
	 *  document ready
	 *
	 *  This function will render each map when the document is ready ( page has loaded )
	 *
	 *  @type	function
	 *  @date	8/11/2013
	 *  @since	5.0.0
	 *
	 *  @param	n/a
	 *  @return	n/a
	 */

	// global var.
	var map = null;

	var initMap = function() {
		if ( $( '.google-map' ).length ) {
			$( '.google-map' ).each( function(){

				// Create map.
				map = new_map( $( this ) );
				$( this ).addClass( 'google-map--initialized' );

			} );
		}
	};

	$( document ).ready( function() {
		initMap();
	} );

} )( jQuery );
