/**
 * The main js file for the theme.
 *
 * This is the entry point where all modules are imported and compiled.
 *
 * @package  embark
 * @since    1.0.0
 */

/**
 * Declares global variables tied to the window object.
 *
 * @since 1.0.0
 */
import './modules/theme/_global-variables';
import './modules/theme/_polyfills';

/**
 * Font-loading with 'fontfaceobserver'.
 *
 * @since 1.0.0
 */
import './modules/theme/webfonts';

/**
 * Lazy-load images with 'vanilla-lazyload',
 * and initialize picturefill after they've loaded.
 * Add object-fit support.
 *
 * @since 1.0.0
 */
import './modules/theme/images';

/**
 * Add classes to image and video thumbnail links, and setup
 * click events to open the image/video in a Colorbox modal.
 *
 * @since 1.0.0
 */
import './modules/theme/lightboxes';

/**
 * Make file inputs and select elements easier to style.
 *
 * @since 1.0.0
 */
import './modules/theme/forms';

/**
 * Scroll to hash on click. Scroll to hash on load.
 *
 * @since 1.0.0
 */
import './modules/theme/scroll-to';

/**
 * Stickyfill polyfill for CSS "position: sticky".
 *
 * @since 1.0.0
 */
import './modules/theme/stickyfill';

/**
 * Toggle 'scrolled' class on sticky header.
 *
 * @since 1.0.0
 */
import './modules/theme/sticky-header';

/**
 * Toggle 'scrolled' class on sticky header.
 *
 * @since 1.0.0
 */
import './modules/theme/main-menu';

/**
 * Adds site-search UI functions.
 *
 * @since 1.0.0
 */
import './modules/theme/site-search';

/**
 * Google Map Embeds.
 *
 * @since 1.0.0
 */
import './modules/theme/google-maps';

/**
 * Layers.
 *
 * @since 1.0.0
 */
// Layer Type: Slider with Image.
import './modules/theme/layer-slider-w-img';
// Layer Type: Tabbed (left nav + content w/ callouts slider).
import './modules/theme/layer-tabbed';
// Layer Type: Timeline.
import './modules/theme/layer-timeline';
// Layer Type: Sticky Nav Group.
import './modules/theme/layer-sticky-nav-group';

/**
 * Masthead.
 *
 * @since 1.0.0
 */
import './modules/theme/masthead';

/**
 * Tablesaw
 *
 * @since 1.0.0
 */
import './modules/theme/tablesaw';
