/**
 * Modals ( also known as lightboxes, colorboxes, etc ).
 *
 * @package  embark
 * @since    1.0.0
 */

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

/**
 * Imports tablesaw.
 *
 * @see https://github.com/filamentgroup/tablesaw
 *
 * @since 1.0.0
 */
import Tablesaw from 'tablesaw';

Tablesaw.init(); //works great
