/**
 * Stickyfill polyfill.
 *
 * @package  JWCI
 * @since    1.0.0
 */

/**
 * Stickyfill
 *
 * Polyfill for CSS `position: sticky`.
 *
 * @see https://github.com/wilddeer/stickyfill
 *
 * @since 1.0.0
 */
import Stickyfill from 'stickyfilljs';

/**
 * Do stickyfill polyfill on all elements that have class "sticky".
 *
 * @since 1.0.0
 */
function initStickyfill() {
	const stickyEls = document.querySelectorAll( '.sticky' );
	Stickyfill.add( stickyEls );
}

document.addEventListener('DOMContentLoaded', initStickyfill);
