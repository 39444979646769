/**
 * Global variables
 *
 * Saves the variables in this file to the window object,
 * so any file has access to them and can read/write, 
 * as opposed to es6 export/imports which can read only.
 *
 * @package  embark
 * @since    1.0.0
 */

/**
 * Defines a user intended scroll, vs a forced programmatic scroll.
 *
 * Used for determining whether to reveal sticky header on scroll-up,
 * or whether to ignore it cuz we forced a scroll up.
 * 
 * @var {bool} userInvokedScroll
 */
window.userInvokedScroll = true;