/**
 * Layer Type: Tabbed content with callouts
 *
 * @package  JWCI
 * @since    1.0.0
 */

/**
 * Import dependencies.
 */
import $ from 'jquery';
import slick from 'slick-carousel';
import * as helpers from '../global/helpers';
import {els, offsetAmount} from './variables';

/**
 * Slider layers
 * @type {NodeList}
 */
const tabbed = document.querySelectorAll( '.tabbed' );

/**
 * Do synchronized slick sliders.
 */
function initLayerTabbed() {
	
	$( tabbed ).each( function() {
		
		// This layer instance.
		let $this = $( this );

		// This layer's content slider.
		let $slider = $( this ).find( '.tabbed__slides' );
		if ( $slider.find( '.tabbed__slide' ).length > 1 ) {
			
			// Content slider properties.
			let sliderProps = {
				adaptiveHeight: true ,
				arrows: false ,
				dots: false ,
				draggable: false ,
				fade: true 
			};

			// Initialize the content slider.
			$slider.slick( sliderProps );

			// On before slide change, update layer class.
			$slider.on( 'beforeChange', function( event, slick, currentSlide, nextSlide ) {

				// Update pager.
				$this.find( '.sidebar-menu .menu-item' ).siblings().removeClass( 'current-menu-item' );
				$this.find( '.sidebar-menu .menu-item' ).eq( nextSlide ).addClass( 'current-menu-item' );

			});

			// Initialize pager click events.
			$(document).on( 'click', '.tabbed .sidebar-menu .menu-item', function() {
				handleTabbedPaging( this, $slider );
			});

			// Initialize mobile pager click events.
			$(document).on( 'click', '.tabbed .sidebar-nav', function() {
				handleMobileToggle( this, $slider );
			});

			// On load, maybe scroll to active tab if in URL on load.
			maybeScrollToHashedSlide( $slider );
		
		}

	} );
};

function handleTabbedPaging( el, $slider ) {	
	const pagerIndex = $( el ).index( this );
	$slider.slick( 'slickGoTo', pagerIndex, false );
}

function handleMobileToggle( el, $slider ) {
	if ( $( window ).width() < 768 ) {				
		$( el ).toggleClass( 'sidebar-nav--open' );
	}
}

/**
 * Fires on slick "init" event
 *
 * Conditionally scrolls the document to the active slide if URL hash exists on document load and slick initialization. 
 * If a corresponding hash slide is found, slick switches to the hashed slide based on slide ID && scrolls to top of slider.
 */
function maybeScrollToHashedSlide( $slider ) {
	
	$(window).on('load', function(){

		const activeTab = helpers.getUrlParameter( 'tab' );

		if ( activeTab && activeTab.length > 0 ) {
			const requestedTabLabel = $( '#' + activeTab );
			if ( requestedTabLabel.length > 0 ) {
				$(requestedTabLabel).click();

				// If there's a hash in the URL, check if its a slide.
				if( window.location.hash ) {
					const hashEl = $( window.location.hash );
					if ( hashEl && hashEl.length > 0 ) {
						// See variables.js for offsetAmount() method.
						helpers.scrollToEl( hashEl, offsetAmount() + 48 );
					}
				} else {
					// See variables.js for offsetAmount() method.
					helpers.scrollToEl( $(requestedTabLabel), offsetAmount() + 48 );
				}
			}
		}
	});
	
};

/**
 * Add click event for links to tabs.
 *
 * Inspects the href for ?tab={slideLabelID} and will simulate a click
 * and scroll to the hash if exists.
 */
$(document).on( 'click', '[href*="tab="]', function() {
	const $this       = $(this);
	const queryString = $this[0].search;
	const queryArray  = parseQuery( queryString );
	const tabName     = queryArray.tab;
	const tab         = $( '#' + tabName );
	const hash        = $this[0].hash;

	if ( tab && tab.length > 0 ) {
		$(tab).click();
		if ( hash.length > 0 ) {
			setTimeout( function() {
				// See variables.js for offsetAmount() method.
				helpers.scrollToEl( $(hash), offsetAmount() );
			}, 100 );
		} else {
			setTimeout( function() {
				// See variables.js for offsetAmount() method.
				helpers.scrollToEl( $(tab), offsetAmount() );
			}, 100 );
		}
	}
});

/**
 * Parse a query string.
 * @param {*} queryString 
 */
function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

window.addEventListener( 'DOMContentLoaded', initLayerTabbed );