/**
 * Masthead compact reveal.
 *
 * @package  Embark
 * @since    1.0.0
 */

/**
 * Import jQuery.
 *
 * @since 1.0.0
 */
import $ from 'jquery';

/**
 * A collection of helper functions for use throughout the project.
 *
 * @since 1.0.0
 */
import * as helpers from '../global/helpers';

/**
 * Common variables used throughout the project.
 *
 * @since 1.0.0
 */
import { els } from './variables';

function handleMastheadCompact() {

	if ( $( '.masthead-compact' ).hasClass( 'masthead-compact--fixed' ) ) {

		if ( helpers.isElementPartiallyInViewport( $( '.masthead' ) ) ) {
			$( '.masthead-compact' ).removeClass( 'masthead-compact--inview' );
		} else {
			$( '.masthead-compact' ).addClass( 'masthead-compact--inview' );
		}

	}

}

if ( $('.masthead').length > 0 ) {
	$( window ).on( 'DOMContentLoaded load resize scroll', handleMastheadCompact ); 
}
