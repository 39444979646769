/**
 * Layer Type: Sticky Nav with Layers Group
 *
 * @package  JWCI
 * @since    1.0.0
 */

/**
 * Import dependencies.
 */
import $ from 'jquery';

/**
 * Layer groups
 *
 * @type {NodeList}
 */
const layerGroups = document.querySelectorAll( '.layer-group' );

/**
 * Callback for when document has finished loading.
 * Initializes the sticky nav group functionality.
 */
const initLayerGroups = () => {

	// Return immediately if theres no sticky nav groups.
	if ( layerGroups.length < 1 ) {
		return;
	}

	// Iterate through each sticky nav group (there's probably only one on the page but good practice in case there's more).
	$( layerGroups ).each( function() {
		// Cache this selector.
		let $this = $( this );
		// Get the sticky nav group's nav and its layers.
		const $nav    = $this.find( '.section-nav' );
		const $layers = $this.find( '.layer' );

		// Iterate through sticky nav group's layers, and initialize a scroll event listener for each layer.
		$layers.each( function(i) {
			initLayerScrollListener( $layers[i], $nav );
		} );

		// Initialize mobile pager click events.
		$(document).on( 'click', '.section-nav__items, .section-nav a', function() {
			handleLayerNavToggle( this );
		});

	} );

}

/**
 * Adds an event listener for this layer.
 *
 * Watches the layer's scroll position to determine which navItem
 * should be active.
 *
 * @param {node}   el  The layer node.
 * @param {object} nav jQuery object for section nav.
 */
const initLayerScrollListener = ( el, nav ) => {

	window.addEventListener( 'scroll', function() {
		watchLayer( el, nav );
	} );

}

/**
 * The callback fired on scroll event.
 *
 * Calculates layer position from top of viewport, accounting
 * for the section nav height and offset. Adds the active class
 * if the layer is in view and not scrolled past the nav.
 *
 * @param {node}   el  The layer node.
 * @param {object} nav jQuery object for section nav.
 */
const watchLayer = ( el, nav ) => {

	// Layer position from top of viewport.
	let rect            = el.getBoundingClientRect();
	let marginTop       = $( el ).css( 'marginTop' ).replace( 'px', '' );
	let marginBottom    = $( el ).css( 'marginBottom' ).replace( 'px', '' );
	let elFromTop       = rect.top - marginTop; // when element comes into view.
	let elBottomFromTop = rect.bottom - marginBottom;

	// Nav position.
	let navRect   = nav[0].getBoundingClientRect();
	let navOffset = navRect.top + navRect.height;

	if ( elFromTop < navOffset && elBottomFromTop > navOffset ) {
		activateAnchor( el, nav );
	} else {
		deactivateAnchor( el, nav );
	}

}

/**
 * Activates the nav item.
 *
 * @param {node}   el  The layer node.
 * @param {object} nav jQuery object for section nav.
 */
const activateAnchor = ( el, nav ) => {
	$( nav ).find( '[href*="#' + el.id + '"]' ).addClass( 'active' );
}

/**
 * Deactivates the nav item.
 *
 * @param {node}   el  The layer node.
 * @param {object} nav jQuery object for section nav.
 */
const deactivateAnchor = ( el, nav ) => {
	$( nav ).find( '[href*="#' + el.id + '"]' ).removeClass( 'active' );
}

const handleLayerNavToggle = el => {
	if ( $( window ).width() < 768 ) {				
		$( '.section-nav__items' ).toggleClass( 'section-nav__items--open' );
	}
}

/**
 * Kicks things off on load.
 */
window.addEventListener( 'DOMContentLoaded', initLayerGroups );
