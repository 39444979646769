/**
 * Defines helper functions that may be imported into any js partial.
 *
 * @package  embark
 * @since    1.0.0
 */

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @since 1.0.0
 *
 * @param  {Function} 	func      	Function to debounce
 * @param  {Number} 	wait      	Time to wait in milliseconds
 * @param  {Boolean} 	immediate 	If true is passed, trigger the function on the leading edge, instead of the trailing.
 * @return {Function}
 */
export function debounce(func, wait = 100, immediate = true) {
	var timeout;
	return function() {
		var context = this,
			args    = arguments;
		var later   = function() {
			timeout = null;
			if ( ! immediate) {
				func.apply( context, args );
			}
		};
		var callNow = immediate && ! timeout;
		clearTimeout( timeout );
		timeout = setTimeout( later, wait );
		if (callNow) {
			func.apply( context, args );
		}
	};
};

/**
 * Loop through an array/nodelist and call the passed callback function.
 *
 * @since 1.0.0
 *
 * @param  {Array}   	array    	Array to be looped over.
 * @param  {Function} 	callback 	Callback function.
 * @param  {???}   		scope
 */
export function forEach(array, callback, scope) {

	// Stores the length of the array.
	const numItems = array.length;

	// Loops through the array.
	for (var i = 0; i < numItems; i++) {
		callback.call( scope, i, array[i] );
	}
}

/**
 * Append a hash to the current URL.
 *
 * @since 1.0.0
 *
 * @param {String} hash
 */
export function addHashToURL(hash) {
	if (history.pushState) {
		const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + hash;
		window.history.pushState(
			{
				path: newurl
			}, '', newurl
		);
	}
}

/**
 * Scroll the document to the provided element.
 *
 * @since 1.0.0
 *
 * @param  {Object} 	el     	jQuery object
 * @param  {Integer} 	offset 	Offset from the top of the viewport
 */
export function scrollToEl(el, offset) {

	// Define a custom event we'll use to listen for when the animation has completed.
	const customScrollEvent = new CustomEvent('ScrollToEl:onComplete');
	
	$( 'html, body' ).animate(
		{
			scrollTop: el.offset().top - offset
		}, 500, 'swing', function() {
			// Dispatch the event so we can listen to when animate is complete.
			window.dispatchEvent(customScrollEvent);
		}
	);
}

/**
 * Check if element is fully visible within the viewport.
 *
 * @since 1.0.0
 *
 * @param  {Object}  el HTMLElement or jQuery Object.
 * @return {Boolean}
 */
export function isElementInViewport(el) {

	// Special bonus for those using jQuery.
	if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}

	var rect = el.getBoundingClientRect();

	return (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
	rect.right <= (window.innerWidth || document.documentElement.clientWidth)/*or $(window).width() */);
}

/**
 * Check if element is partially visible within the viewport.
 *
 * @since 1.0.0
 *
 * @param  {Object}  el HTMLElement or jQuery Object.
 * @return {Boolean}
 */
export function isElementPartiallyInViewport( el ) {

	// Special bonus for those using jQuery.
	if (typeof jQuery === "function" && el instanceof jQuery) {
		el = el[0];
	}

	var elementTop = $(el).offset().top;
	var elementBottom = elementTop + $(el).outerHeight();
	var viewportTop = $(window).scrollTop();
	var viewportBottom = viewportTop + $(window).height();
	
	return elementBottom > viewportTop && elementTop < viewportBottom;
};
export function isTouchDevice() {
	return navigator.userAgent.match( /Tablet|iPad|Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i ) !== null;
}

/**
 * Retrieve a URL parameter from the current URL.
 *
 * @since 1.0.0
 *
 * @param {String} name The name of the parameter key to retrieve a value from in the URL.
 */
export function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};