import AOS from 'aos';
import * as helpers from '../global/helpers';

function initTimeline() {

	/**
	 * Get all .timeline elements.
	 * @type {NodeList}
	 */
	const timelines = document.querySelectorAll( '.timeline' );

	/**
	 * Proceed only if we have timelines.
	 */
	if ( timelines.length > 0 ) {
		/**
		 * Loop over each timeline.
		 */
		helpers.forEach( timelines, ( timelineCount, timeline ) => {
			/**
			 * Get all milestones within this timeline.
			 * @type {[type]}
			 */
			const milestones = timeline.querySelectorAll( '.timeline-item' );

			/**
			 * Loop over each milestone.
			 */
			helpers.forEach( milestones, ( milestoneCount, milestone ) => {

				/**
				 * Set AOS attribtues for this milestone.
				 */
				milestone.setAttribute( 'data-aos-anchor-placement', 'top-bottom' );

				/**
				 * Alternate between 'fade-left' and 'fade-right' AOS animations.
				 */
				if ( milestoneCount % 2 === 0 ) {
					milestone.setAttribute( 'data-aos', 'fade-left' );
				} else {
					milestone.setAttribute( 'data-aos', 'fade-right' );
				}
			});
		});

		/**
		 * Initialize AOS.
		 */
		AOS.init();
	}
}

document.addEventListener('DOMContentLoaded', initTimeline);
