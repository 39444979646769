/**
 * Sticky header functionality.
 *
 * @package  Embark
 * @since    1.0.0
 */

/**
 * A collection of helper functions for use throughout the project.
 *
 * @since 1.0.0
 */
import * as helpers from '../global/helpers';

/**
 * Common variables used throughout the project.
 *
 * @since 1.0.0
 */
import { els } from './variables';

/**
 * Defines the minimum amount of scroll needed to do anything. 
 *
 * @type {Number}
 */
const minScrollPoint = els.header ? els.header.offsetHeight : false;

/**
 * Tracks the current scroll position on the page.
 *
 * @type {Number}
 */
let currentScrollPoint;

/**
 * The last scrolled y value. 
 *
 * @type {Number}
 */
let lastScrollTop = minScrollPoint;

/**
 * Toggle the header scrolled class.
 *
 * @param  {Event} e
 */
function maybeToggleScrolledClass(e) {
	// Return immediately if no site-header.
	if ( ! els.header || ! window.userInvokedScroll ) {
		return;
	}

	/**
	 * Update the last scrolled position.
	 *
	 * @type {Number}
	 */
	currentScrollPoint = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"

	/**
	 * Check scroll status and toggle .scrolled accordingly.
	 */
	if ( currentScrollPoint > minScrollPoint && currentScrollPoint > lastScrollTop ) {
		// Downscroll code.
		els.body.classList.add( 'scrolled' );
	} else {
		// Upscroll code.
		els.body.classList.remove( 'scrolled' );
	}

	lastScrollTop = currentScrollPoint;
}

window.addEventListener( 'scroll', maybeToggleScrolledClass );
window.addEventListener( 'load', maybeToggleScrolledClass );
