/**
 * Layer Type: Slider with Image
 *
 * @package  JWCI
 * @since    1.0.0
 */

/**
 * Import dependencies.
 */
import $ from 'jquery';
import slick from 'slick-carousel';
import * as helpers from '../global/helpers';
import {els} from './variables';

/**
 * Slider layers
 * @type {NodeList}
 */
const sliderLayers = document.querySelectorAll( '.layer--slider-w-img' );

/**
 * Do synchronized slick sliders.
 */
function initSliderWithImage() {
	
	$( sliderLayers ).each( function() {
		
		// This layer instance.
		let $this = $( this );

		// This layer's content slider.
		let $contentSlider = $( this ).find( '.slides' );
		let $pager         = $( this ).find( '.slider__pager' );
		if ( $contentSlider.find( '.slide' ).length > 1 ) {
			
			// Content slider properties.
			let sliderProps = {
				appendArrows: $this.find( '.slider__controls' ) ,
				arrows: true ,
				dots: false ,
				fade: true , 
				infinite: true ,
				nextArrow: '<button type="button" class="slider-arrow slider-arrow--next slick-next"><span class="screen-reader-text">Next</span></button>',
				prevArrow: '<button type="button" class="slider-arrow slider-arrow--prev slick-prev"><span class="screen-reader-text">Previous</span></button>'
			};

			if ( $pager.length ) {
				sliderProps.asNavFor = $pager;
			}

			if ( $contentSlider.closest( '.layer--testimonial-w-img' ).length > 0 ) {
				sliderProps.autoplay       = true;
				sliderProps.autoplaySpeed  = 10000;
			}

			// Add active states to custom pager and associated image.
			$this.find( '.media-slide:first-child' ).addClass( 'active' );
			updateColor( $this.find( '.slide:first-child' ), $this );

			// Initialize the content slider.
			$contentSlider.slick( sliderProps );
			
			// Initialize the pager.
			$pager.slick({
			  asNavFor: $contentSlider,
			  arrows: false ,
			  focusOnSelect: true,
			  slidesToScroll: 1,
			  slidesToShow: 1,
			  variableWidth: true
			});

			// On before slide change, update layer class.
			$contentSlider.on( 'beforeChange', function( event, slick, currentSlide, nextSlide ) {

				// Update layer background color class.
				updateColor( slick.$slides.eq( nextSlide ).find(' .slide' ), $this );

				// Update pager.
				// $this.find( '.slider__pager-item' ).siblings().removeClass( 'active' );
				// $this.find( '.slider__pager-item' ).eq( nextSlide ).addClass( 'active' );

				// Show the correct slide image
				$this.find( '.media-slide' ).removeClass( 'active' );
				$this.find( '.media-slide' ).eq( nextSlide ).addClass( 'active' );

			});

			// Initialize pager click events.
			// $(document).on( 'click', '.slider__pager-item', function() {
			// 	handlePaging( this, $contentSlider );
			// });
		
		}

	} );
};

function handlePaging( el, $slider ) {	
	const pagerIndex = $( el ).index( this );
	$slider.slick( 'slickGoTo', pagerIndex, false );
}

function updateColor( $el, $layer ) {
	
	// Stores the background color value for this slide.
	if ( $el.data( 'color' ) ) {
		// Removes all background color classes.
		$layer.removeClass( function( index, className ) {
			return ( className.match (/(^|\s)bg-\S+/g) || []).join(' ');
		} );
		// Adds the background color class.
		$layer.addClass( 'bg-' +  $el.data( 'color' ) );
	}

}

window.addEventListener( 'DOMContentLoaded', initSliderWithImage );